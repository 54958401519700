<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <a-row style="display: flex">
        <a-col><breadcrumb></breadcrumb></a-col>
      </a-row>
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="createHandle">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('添加发票') }}
        </a-button>
      </section>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input
            style="width: 100%"
            v-model="formData.invoice_no"
            :placeholder="$t(`发票号码`)"
          />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.invoice_code" :placeholder="$t(`发票代码`)" />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            allowClear
            id="effectiveTime"
            :needhms="false"
            @get_date="chooseOrderStart"
            :placeholder="$t(`开票日期`)"
          />
        </a-col>
        <a-col :span="6">
          <SelectSupplier :code.sync="formData.supplierCodeAndName" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt10">
        <a-col :span="6">
          <a-button type="primary" @click="searchHandle">
            <i class="iconfont icon-search" style="margin-right: 5px" />
            {{ $t(`搜索`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="sync" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>

      <div class="table-content mt10">
        <div class="table-body">
          <CommonTable
            ref="CommonTableRef"
            :statusList="invoice_status"
            :columns="columns"
            :scroll="{ x: 1800 }"
            :data-source="tableList"
            :loading="loading"
            :total="total"
            rowKey="id"
            @search="getList"
          >
            <template v-slot:invoiceNo="{ text, record }">
              <span class="text-link" @click="viewDetail(record)">{{ text }}</span>
            </template>
            <template v-slot:buyerInfo="{ record }">
              <div>{{ record.buyer_name }} {{ record.buyer_mobile }}</div>
              <div>{{ record.buyer_bank_name }} {{ record.buyer_bank_account }}</div>
            </template>
          </CommonTable>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DateRangePicker from '@component/dateRangePicker'
import CommonTable from '@component/commonTable'
import SelectSupplier from '@component/selectSupplier'
export default {
  name: 'invoiceManage',
  mounted() {
    this.searchHandle()
  },
  components: {
    DateRangePicker,
    CommonTable,
    SelectSupplier,
  },
  data() {
    return {
      loading: false,
      tableList: [],
      formData: {
        invoice_no: undefined,
        invoice_code: undefined,
        supplierCodeAndName: undefined,
        startTime: undefined,
        endTime: undefined,
        invoiceDateStart: undefined,
        invoiceDateEnd: undefined,
      },
      total: 0,
      CommonTableRef: null,
    }
  },
  computed: {
    ...mapState(['purchaseStatusList', 'invoice_status']),
    ...mapGetters(['invoiceTypeMapping', 'invoiceStatusMapping']),
    tableHeight() {
      return (window.innerHeight - 400) * this.$store.state.rate
    },
    columns() {
      return [
        {
          title: this.$t(`发票号码`),
          dataIndex: 'invoice_no',
          scopedSlots: { customRender: 'invoiceNo' },
        },
        {
          title: this.$t(`发票代码`),
          dataIndex: 'invoice_code',
        },
        {
          title: this.$t(`发票金额`),
          dataIndex: 'amount_with_tax',
        },
        {
          title: this.$t(`发票状态`),
          dataIndex: 'status',
          customRender: (text) => this.invoiceStatusMapping[text],
        },
        {
          title: this.$t(`开票时间`),
          dataIndex: 'invoice_date',
        },
        {
          title: this.$t(`发票类型`),
          dataIndex: 'invoice_type',
          customRender: (text) => this.invoiceTypeMapping[text],
        },
        {
          title: this.$t(`税率`) + '(%)',
          dataIndex: 'tax_rate',
        },
        {
          title: this.$t(`税额`),
          dataIndex: 'tax_amount',
        },
        {
          title: this.$t(`不含税金额`),
          dataIndex: 'amount_without_tax',
        },
        {
          title: this.$t(`开票人纳税人识别号`),
          dataIndex: 'buyer_taxpayer_code',
        },
        {
          title: this.$t(`开票人信息`),
          key: 'buyerInfo',
          scopedSlots: { customRender: 'buyerInfo' },
        },
        {
          title: this.$t(`备注`),
          dataIndex: 'remark',
        },
        // {
        //   title: this.$t(`操作`),
        //   width: 120,
        //   fixed: 'right',
        //   scopedSlots: { customRender: 'Action' },
        // },
      ]
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    searchHandle() {
      this.$refs.CommonTableRef.queryList()
    },
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle()
    },
    getList(params) {
      this.loading = true
      let data = {
        ...this.formData,
        ...params,
      }
      http({
        type: 'post',
        url: api.getInvoiceList,
        data,
        success: (res) => {
          this.loading = false
          this.tableList = res.result.list
          this.total = res.result.total
        },
      })
    },
    createHandle() {
      this.$router.push({
        name: 'invoiceDetail',
      })
    },
    chooseOrderStart(date) {
      this.formData.invoiceDateStart = date.start_time
      this.formData.invoiceDateEnd = date.end_time
    },
    viewDetail(record) {
      this.addPane({
        view: 'invoiceDetail/' + record.invoice_no,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.invoice_no,
        },
        route: `/invoiceManage/invoiceDetail?id=${record.id}&sn=${record.invoice_no}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.ant-form-item {
  margin-bottom: 0;
}
</style>
